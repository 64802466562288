import { useQuery, useMutation } from "@tanstack/react-query";
import { BASE_URL, AUTH_DEBUG, HttpResponse, HttpStatus, } from "../default";

const categoriesResumeHome = async () => {
    const url = `${BASE_URL}/partner/servicesHome/`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("PartnerAPI::getCategoriesResumeHome(): ", data);
        return data;
    } else {
        throw new Error("Error on getCategoriesResumeHome()");
    }
};

export function useCategoriesResumeHomeData() {
    const query = useQuery({
        queryKey: ['CategoriesResumeHome'],
        queryFn: () => categoriesResumeHome(),
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 10,
        retry: 0,
    });

    return {
        ...query,
        data: query.data,
    };
}

const servicesAndPartnerSearch = async (serviceName, selectedTag, selectedCity, selectedType, pagePagination) => {
    const url = `${BASE_URL}/partner/searchPartnerServicesProvided/?serv=${serviceName}&tags=${selectedTag}&loc=${selectedCity}&type=${selectedType}&page=${pagePagination}`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("PartnerAPI::servicesAndPartnerSearch(): ", data);
        return data;
    } else {
        throw new Error("Error on servicesAndPartnerSearch()");
    }
};

export function useServicesAndPartnerSearchData(serviceName, selectedTag, selectedCity, selectedType,  pagePagination, searchApproved) {
    const query = useQuery({
        queryFn: () => servicesAndPartnerSearch(serviceName, selectedTag, selectedCity, selectedType, pagePagination),
        enabled: !!searchApproved,
        retry: 0,
    });

    return {
        ...query,
        data: query.data,
    };
}

const getDaysSchedulesPartnerSearch = async (partnerSlug, service_id, start_date, end_date) => {
    const url = `${BASE_URL}/partner/schedules/partner/${partnerSlug}/service/${service_id}/?start_date=${start_date}&end_date=${end_date}`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("PartnerAPI::getDaysSchedulesPartner(): ", data);
        return data;
    } else {
        throw new Error("Error on getDaysSchedulesPartner()");
    }
};

export function useDaysSchedulesPartnerSearchData(partnerSlug, service_id, start_date, end_date) {

    const query = useQuery({
        queryKey: ['DaysSchedulesPartnerSearch', partnerSlug],
        queryFn: () => {
            return getDaysSchedulesPartnerSearch(partnerSlug, service_id, start_date, end_date);
        },
        staleTime: 1000 * 60 * 30,
        cacheTime: 1000 * 60 * 30,
        retry: 0,
    });

    return {
        ...query,
        data: query.data,
    };
}

const postScheduleEmployeePartner = async ({ formData, token }) => {
    const url = `${BASE_URL}/partner/schedules/`;

    const options = {
        method: 'POST',
        credentials: 'include',
        headers: {
            jwt: token,
        },
        body: formData,
    };

    const response = await fetch(url, options);
    if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData?.message || 'Erro ao criar agendamento';
        throw new Error(errorMessage);
    }
    
    return response.json();
};

export function usePostScheduleEmployeePartner(partner_id, clientId) {
    const mutate = useMutation({
        mutationFn: postScheduleEmployeePartner,
        mutationKey: { partner_id },
        enabled: [!!partner_id && !!clientId],
    });

    return mutate;
}

const postSchedule = async (partnerSlug, formData, token) => {
    const url = `${BASE_URL}/partner/schedules/partner/${partnerSlug}/`;
    var errorMessage;
    try {
        const options = {
            method: 'POST',
            credentials: 'include',
            headers: {
                jwt: token,
            },
            body: formData,
        };

        const response = await fetch(url, options);
        if (response.ok) {
            const data = await response.json();
            AUTH_DEBUG && console.log("SignupAPI::signup(): ", data.token);
            return new HttpResponse(HttpStatus.OK, data);
        } else {
            errorMessage = await response.json();
            throw new Error("Error on Signup()")
        }
    } catch (error) {
        console.warn(error)
        return new HttpResponse(HttpStatus.ERROR, errorMessage);
    }
}

export const SearchAPI = {
    postSchedule,
}