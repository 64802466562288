import React, { useState } from 'react';
import { Container, Row, Col, Button, Image, Card } from 'react-bootstrap';
import styles from "./style.module.scss"
import { Helmet, HelmetProvider } from 'react-helmet-async';
// import { useAuthContext } from '../../contexts/authContext';
import NoImage from "../../assets/no-image.jpg";
import ProgressBar from '../../components/progressBar';
import { useNavigate, useParams } from 'react-router-dom';

export default function ClientScheduleDetailsPage() {
    const { schedule_id } = useParams();
    // const { token, user } = useAuthContext();
    const steps = ['Início', 'Processamento', 'Concluído'];
    const [currentStep, setCurrentStep] = useState(1);

    const navigate = useNavigate();

    const handleCheckoutRedirect = () => {
        navigate(`/agendamento/${schedule_id}/checkout`);
    };

    return (
        <HelmetProvider>
            <section id={styles.ClientScheduleDetailsPage}>
                <Helmet>
                    <title>Agendamento - GoinGuru</title>
                    <meta name="description" content="GoinGuru" />
                </Helmet>
                <Container>
                    <Row className={styles.rowTitle}>
                        <p>Detalhes do Agendamento -  Nº ...</p>
                    </Row>
                    <Row className={styles.rowButton}>
                        <Col className={styles.colButtonCancelar}>
                            <Button className={styles.buttonCancelar}>Voltar</Button>
                        </Col>
                        <Col>
                            <Button className={styles.button}>Preciso de Atendimento</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Card className={styles.cardClientSchedule}>
                            <Row className={styles.rowClientSchedule}>
                                <Col xs={3} sm={4} md={3} lg={2} xl={2} className={styles.colPartnerSchedule}>
                                    <Image
                                        src={NoImage}
                                        className={styles.image}
                                    />
                                </Col>
                                <Col xs={9} sm={8} md={7} lg={8} xl={8} className={styles.colServiceSchedule}>
                                    <p><strong>Serviço:</strong> Nome do serviço</p>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                            <p className={styles.text}>
                                                <strong>Data:</strong> 12/09/2024 das 11:00hrs às 12:00hrs
                                            </p>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                                            <p className={styles.subText}>
                                                <strong>Preço:</strong> R$ 250,00
                                            </p>
                                        </Col>
                                    </Row>
                                    <p><strong>Observações:</strong> dhsuadhas uhdsu hdauhd aus huas udhasud hud hasu hasudh audh aidhuaih uia</p>
                                    <p className={styles.subText}>
                                        <strong>Tipo do serviço: </strong> 
                                        No local
                                    </p>
                                </Col>
                                <Col xs={12} sm={12} md={2} lg={2} xl={2} className={styles.colEmployeeSchedule}>
                                    <p><strong>Colaborador:</strong> Diluc</p>
                                    <Image
                                        src={NoImage}
                                        className={styles.image}
                                    />
                                </Col>
                            </Row>
                            <Row className={styles.rowPartnerSchedule}>
                                <Col xs={3} sm={3} md={2} lg={2} xl={2} className={styles.colPartnerSchedule}>
                                    <Image
                                        src={NoImage}
                                        className={styles.image}
                                    />
                                </Col>
                                <Col xs={9} sm={9} md={10} lg={10} xl={10}>
                                    <p><strong>Empresa:</strong> Nome da empresa</p>
                                    <Row>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <p><strong>CNPJ:</strong> 61.783.647/0001-21</p>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <p><strong>WhatsApp:</strong> (11)94564-1234</p>
                                        </Col>
                                    </Row>
                                    <p><strong>Endereço:</strong> 
                                        Endereço completo
                                    </p>
                                </Col>
                            </Row>
                        </Card>
                    </Row>
                    <Row className={styles.rowPayment}>
                        <Card className={styles.cardPaymentSchedule}>
                            <p className={styles.paymentTitle}>Status</p>
                            <ProgressBar steps={steps} currentStep={currentStep} />
                            {currentStep >= 2 && (
                                <>
                                    <Row className={styles.rowPaymentText}>
                                        <p className={styles.paymentText}>Pagamento Aprovado</p>
                                        <Col>
                                            <p className={styles.paymentSubText}>Forma de pagamento:</p>
                                            <p className={styles.paymentSubText}>Cartão de crédito MasterCard</p>
                                        </Col>
                                        <Col>
                                            <p className={styles.paymentSubText}>52341********1561</p>
                                            <p className={styles.paymentSubText}>a vista de R$ 250,00</p>
                                        </Col>
                                    </Row>
                                    <Row className={styles.rowCancelSchedule}>
                                            <Button 
                                                className={styles.buttonCheckout}>
                                                Cancelar Agendamento
                                            </Button>
                                    </Row>
                                </>
                            )}
                            {currentStep === 1 && (
                                <Row className={styles.rowCheckoutButton}>
                                    <Col className={styles.colButtonCancelar}>
                                        <Button className={styles.buttonCancelar}>Recusar / Cancelar</Button>
                                    </Col>
                                    <Col>
                                        <Button 
                                            onClick={handleCheckoutRedirect} 
                                            className={styles.buttonCheckout}>
                                            Ir para o Checkout
                                        </Button>
                                    </Col>
                                </Row>
                            )}
                        </Card>
                    </Row>
                </Container>
            </section>
        </HelmetProvider>
    );
}