import React, { useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Form, Button, Card, Spinner } from 'react-bootstrap';
import styles from "./style.module.scss";
import Select from 'react-select';
import { useAuthContext } from '../../contexts/authContext';
import { toast } from 'react-toastify';
import { useGetStatesAndCitiesData, UserAPI } from '../../api/userAPI/userClient';
import debounce from 'lodash.debounce';

export default function SectionProfileAddress({ data }) {
    const { token, user } = useAuthContext();
    const [isEditing, setIsEditing] = useState(false);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState('');
    const [filterCity, setFilterCity] = useState("");
    const [isLoadingButton, setIsLoadingButton] = useState(false);

    const { CitiesAndStatesData, isLoading, isError, refetch } = useGetStatesAndCitiesData(token, filterCity ? filterCity : data?.city?.name);

    const CityOptions = useMemo(() => 
        CitiesAndStatesData?.map(city => ({
            value: city.id, 
            label: city.name,
            state: {
                id: city.state.id,
                name: city.state.name,
                abbreviation: city.state.abbreviation,
            }
        })) || [], 
        [CitiesAndStatesData]
    );

    const [formValues, setFormValues] = useState({
        cep: '',
        state: '',
        city: '',
        neighborhood: '',
        street: '',
        number: '',
        complement: '',
    });
    const [originalValues, setOriginalValues] = useState({});
    const [errors, setErrors] = useState({});
    const [isDataInitialized, setIsDataInitialized] = useState(false);

    useEffect(() => {
        if (data && CitiesAndStatesData && !isDataInitialized) {
            const cityData = CitiesAndStatesData.find(city => city.id === data.city.id);

            const stateData = cityData ? { 
                id: cityData.state.id,
                name: cityData.state.name, 
                abbreviation: cityData.state.abbreviation 
            } : null;

            setSelectedCity(cityData ? { value: cityData.id, label: cityData.name } : null);
            setSelectedState(cityData ? { id: cityData.state.id, name: cityData.state.name, abbreviation: cityData.state.abbreviation } : null);
            setFilterCity(data.city.name);

            setFormValues({
                cep: data.cep || '',
                state: stateData ? stateData.label : '',
                city: cityData ? cityData.label : '',
                neighborhood: data.neighborhood || '',
                street: data.street || '',
                number: data.number || '',
                complement: data.complement || '',
            });

            setOriginalValues({
                cep: data.cep || '',
                state: stateData ? stateData.label : '',
                city: cityData ? cityData.label : '',
                neighborhood: data.neighborhood || '',
                street: data.street || '',
                number: data.number || '',
                complement: data.complement || '',
            });

            setIsDataInitialized(true);
        }
    }, [data, CitiesAndStatesData, isDataInitialized]);

    const debouncedFilterCity = useMemo(() => 
        debounce((value) => {
            refetch({ token, filterCity: value });
        }, 1000), [token, refetch]
    );

    useEffect(() => {
        if (!selectedCity) {
            debouncedFilterCity(filterCity);
        }
    }, [filterCity, selectedCity, debouncedFilterCity]);
    

    const validateForm = () => {
        const newErrors = {};
    
        if (!formValues.cep) newErrors.cep = 'O CEP é obrigatório';
        if (!selectedState) newErrors.state = 'O estado é obrigatório';
        if (!selectedCity) newErrors.city = 'A cidade é obrigatória';
        if (!formValues.neighborhood) newErrors.neighborhood = 'O bairro é obrigatório';
        if (!formValues.street) newErrors.street = 'A rua é obrigatória';
        if (!formValues.number) newErrors.number = 'O número é obrigatório';
    
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };    

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormValues(prevValues => ({
            ...prevValues,
            [id]: value
        }));
    };

    const handleSubmitAddress = async (e) => {
        e.preventDefault();
    
        if (!validateForm()) {
            return;
        }
    
        const changedValues = {};
        Object.keys(formValues).forEach(key => {
            if (formValues[key] !== originalValues[key]) {
                changedValues[key] = formValues[key];
            }
        });
        
        if (selectedCity && selectedCity.value !== originalValues.city) {
            changedValues.city = selectedCity.value;
        }
        if (selectedCity && selectedCity.state.id !== originalValues.state) {
            changedValues.state = selectedCity.state.id;
        }
    
        const formData = new FormData();
        
        formData.append('user', user.id);
        if (changedValues.cep) formData.append('cep', changedValues.cep);
        if (changedValues.state) formData.append('state', changedValues.state);
        if (changedValues.city) formData.append('city', changedValues.city);
        if (changedValues.neighborhood) formData.append('neighborhood', changedValues.neighborhood);
        if (changedValues.street) formData.append('street', changedValues.street);
        if (changedValues.number) formData.append('number', changedValues.number);
        if (changedValues.complement) formData.append('complement', changedValues.complement);
    
        if (Object.keys(changedValues).length === 0) {
            handleCancel();
            return;
        }
    
        setIsLoadingButton(true);
    
        try {
            const response = await UserAPI.PostUserAddress(user.id, formData, token);
    
            if (response.status === 200) {
                toast.success('Dados alterados com sucesso!');
                setOriginalValues(formValues);
                setIsEditing(false);
            } else if (response.status === 400) {
                toast.error('Erro ao alterar o cadastro, verifique os dados fornecidos!');
            } else if (response.status === 404) {
                toast.error('Erro ao alterar o cadastro, verifique os dados fornecidos!');
            }
        } catch (error) {
            console.error('Erro ao fazer cadastro:', error.message);
            toast.error('Erro ao fazer cadastro, verifique os dados e tente novamente!');
        }
        setIsLoadingButton(false);
    };    

    const handleCancel = () => {
        setIsEditing(false);
    };

    return (
        <section id={styles.SectionCompanyGuruAddress}>
            <Container>
                <Row className={styles.rowTitleAddress}>
                    <Col xs={6} md={6}>
                        <p className={styles.title}>Endereço</p>
                    </Col>
                    <Col xs={6} md={6} className={styles.colButtonEdit}>
                        {isEditing ? (
                            <Button className={styles.buttonCancel} onClick={handleCancel}>
                                Cancelar
                            </Button>
                        ) : (
                            <Button className={styles.buttonEdit} onClick={() => setIsEditing(true)}>
                                Editar
                            </Button>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Card className={styles.cardAddress}>
                        <Card.Body>
                            <Form onSubmit={handleSubmitAddress} className={styles.form}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <Form.Group controlId="cep">
                                            <Form.Label className={styles.formTitle}>CEP</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Digite seu CEP"
                                                className={styles.formControl}
                                                value={formValues.cep}
                                                onChange={handleChange}
                                                disabled={!isEditing}
                                            />
                                            {errors.cep && <Form.Text className="text-danger">{errors.cep}</Form.Text>}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <Form.Group controlId="street">
                                            <Form.Label className={styles.formTitle}>Rua/Avenida</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Digite sua rua ou avenida"
                                                className={styles.formControl}
                                                value={formValues.street}
                                                onChange={handleChange}
                                                disabled={!isEditing}
                                            />
                                            {errors.street && <Form.Text className="text-danger">{errors.street}</Form.Text>}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <Form.Group controlId="number">
                                            <Form.Label className={styles.formTitle}>Número</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Digite o número"
                                                className={styles.formControl}
                                                value={formValues.number}
                                                onChange={handleChange}
                                                disabled={!isEditing}
                                            />
                                            {errors.number && <Form.Text className="text-danger">{errors.number}</Form.Text>}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <Form.Group controlId="neighborhood">
                                            <Form.Label className={styles.formTitle}>Bairro</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Digite seu bairro"
                                                className={styles.formControl}
                                                value={formValues.neighborhood}
                                                onChange={handleChange}
                                                disabled={!isEditing}
                                            />
                                            {errors.neighborhood && <Form.Text className="text-danger">{errors.neighborhood}</Form.Text>}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <Form.Group controlId="city">
                                            <Form.Label className={styles.formTitle}>Cidade</Form.Label>
                                            <Select
                                                options={CityOptions}
                                                value={selectedCity}
                                                onChange={(selectedCityOption) => {
                                                    setSelectedCity(selectedCityOption);

                                                    if (selectedCityOption) {
                                                        setSelectedState({
                                                            id: selectedCityOption.state.id,
                                                            name: selectedCityOption.state.name,
                                                            abbreviation: selectedCityOption.state.abbreviation,
                                                        });
                                                        setFilterCity("");
                                                    } else {
                                                        setSelectedState(null);
                                                    }
                                                }}
                                                onInputChange={(inputValue) => {
                                                    setFilterCity(inputValue);
                                                }}
                                                placeholder="Digite a Cidade"
                                                styles={customStyles}
                                                isClearable
                                                isDisabled={!isEditing}
                                            />
                                            {errors.city && <Form.Text className="text-danger">{errors.city}</Form.Text>}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <Form.Group controlId="state">
                                            <Form.Label className={styles.formTitle}>Estado</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Estado"
                                                className={styles.formControl}
                                                value={selectedState ? `${selectedState.name} (${selectedState.abbreviation})` : ''}
                                                disabled={true}
                                            />
                                            {errors.state && <Form.Text className="text-danger">{errors.state}</Form.Text>}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group controlId="complement">
                                            <Form.Label className={styles.formTitle}>Complemento</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Digite o complemento"
                                                className={styles.formControl}
                                                value={formValues.complement}
                                                onChange={handleChange}
                                                disabled={!isEditing}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {isEditing && (
                                    <Row className={styles.rowButton}>
                                        <Col xs={6} md={6} className={styles.colButtonCancel}>
                                            <Button className={styles.buttonCancel} onClick={handleCancel}>
                                                Cancelar
                                            </Button>
                                        </Col>
                                        <Col xs={6} md={6} className={styles.colButtonSend}>
                                            <Button className={styles.button} type="submit" disabled={isLoadingButton}>
                                                {isLoadingButton ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Salvar"}
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                            </Form>
                        </Card.Body>
                    </Card>
                </Row>
            </Container>
        </section>
    );
}

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        // marginTop: '5px',
        borderColor: '#EE880C',
        boxShadow: state.isFocused ? '0 0 5px 2px #EE880C' : provided.boxShadow,
        '&:hover': {
            borderColor: '#EE880C'
        }
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        '&:hover': {
            color: '#EE880C'
        }
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#390040' : state.isFocused ? '#EE880C' : provided.backgroundColor,
        color: state.isSelected ? '#fff' : state.isFocused ? '#fff' : provided.color,
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#EE880C',
            color: '#fff'
        }
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#390040',
    }),
};