import React from 'react';
import { Container, Row, Col, Image, Spinner, Alert, Card, Badge } from 'react-bootstrap';
import styles from "./style.module.scss";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useAuthContext } from '../../contexts/authContext';
import { useClientRequestsData } from '../../api/userAPI/userClient';
import NoImage from "../../assets/no-image.jpg";
import { useNavigate } from 'react-router-dom';

export default function ClientRequestsPage() {
    const { token, user } = useAuthContext();
    const navigate = useNavigate();

    const { ClientData, isError: isErrorClient, isLoading: isLoadingClient } = useClientRequestsData(token, user.email);
    
    if (isLoadingClient) {
        return (
            <section id={styles.ClientSchedulesPage}>
                <Container>
                    <Row>
                        <Col className="text-center">
                            <Spinner animation="border" role="status">
                            </Spinner>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }

    if (isErrorClient) {
        return (
            <section id={styles.ClientSchedulesPage}>
                <Container>
                    <Row>
                        <Col>
                            <Alert variant="danger">
                                Erro ao carregar os dados. Por favor, tente novamente mais tarde.
                            </Alert>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }

    return (
        <HelmetProvider>
            <section id={styles.ClientSchedulesPage}>
                <Helmet>
                    <title>Meus Pedidos - GoinGuru</title>
                    <meta name="description" content="GoinGuru" />
                </Helmet>
                <Container>
                    <Row className={styles.rowTitle}>
                        <p>Meus Pedidos</p>
                    </Row>
                    
                    <Row className={styles.rowSchedule}>
                        {!isLoadingClient && !isErrorClient && ClientData && ClientData.map(orderClient => (
                            <Card key={orderClient.id} className={styles.cardClientSchedule} onClick={() => navigate(`/meusPedidos/${orderClient.orderNumber}`)}>
                                <Row className={styles.rowClientSchedule}>
                                    <Col xs={3} sm={4} md={3} lg={2} xl={2} className={styles.colPartnerSchedule}>
                                        <Image
                                            src={orderClient.serviceProvided.photo || NoImage}
                                            className={styles.image}
                                        />
                                    </Col>
                                    <Col xs={9} sm={8} md={7} lg={8} xl={8} className={styles.colServiceSchedule}>
                                        <p><strong>Serviço:</strong> {orderClient.serviceProvided.name}</p>
                                        <Row>
                                            <p className={styles.subText}>
                                                <strong>Preço:</strong> {(orderClient.price ? formatPrice(orderClient.price) : '-')}
                                            </p>
                                        </Row>
                                        <p><strong>Observações:</strong> {orderClient.observations}</p>
                                        <p className={styles.subText}>
                                            <strong>Tipo do serviço: </strong> 
                                            {orderClient.serviceProvided.is_home_service
                                                ? 'Domicílio'
                                                : orderClient.serviceProvided.is_online_service
                                                ? 'Online'
                                                : 'No Local'}
                                        </p>
                                    </Col>
                                    <Col xs={12} sm={12} md={2} lg={2} xl={2} className={styles.colEmployeeSchedule}>
                                        <p><strong>Status:</strong></p>
                                        <Badge
                                            bg={
                                                orderClient.status === "paid"
                                                    ? "success"
                                                    : orderClient.status === "pending"
                                                    ? "warning"
                                                    : orderClient.status === "failed" || orderClient.status === "rejected"
                                                    ? "danger"
                                                    : orderClient.status === "refunded"
                                                    ? "info"
                                                    : orderClient.status === "cancelled_by_c"
                                                    ? "dark"
                                                    : orderClient.status === "cancelled_by_p"
                                                    ? "secondary"
                                                    : orderClient.status === "finished"
                                                    ? "dark"
                                                    : "secondary"
                                            }
                                            className={styles.badge}
                                        >
                                            {orderClient.status === "paid"
                                                ? "Pago"
                                                : orderClient.status === "pending"
                                                ? "Pendente"
                                                : orderClient.status === "failed" || orderClient.status === "rejected"
                                                ? "Falha no Pagamento"
                                                : orderClient.status === "refunded"
                                                ? "Devolvido"
                                                : orderClient.status === "cancelled_by_c"
                                                ? "Cancelamento Cliente"
                                                : orderClient.status === "cancelled_by_p"
                                                ? "Cancelamento Parceiro"
                                                : orderClient.status === "finished"
                                                ? "Concluído"
                                                : "Status Desconhecido"}
                                        </Badge>
                                    </Col>
                                </Row>
                            </Card>
                        ))}
                    </Row>
                </Container>
            </section>
        </HelmetProvider>
    );
}

const formatPrice = (value) => {
    if (!value) return '-';
    const number = parseFloat(value);
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(number);
};
