import React, { useState, useEffect, useMemo } from 'react';
import { Button, Row, Col, Container } from 'react-bootstrap';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import styles from "./style.module.scss";
import { format, addDays, startOfDay, getDay, subDays } from 'date-fns';
import { setDefaultOptions } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useDaysSchedulesPartnerSearchData } from '../../api/homeAPI/homeClient';

setDefaultOptions({ locale: ptBR });

const isValidDurationFormat = (duration) => /^(\d{2}):(\d{2})$/.test(duration);

const generateTimeOptions = (openingTime, closingTime, breakStartTime, breakEndTime, duration, schedules = []) => {
  if (!openingTime || !closingTime || !duration || !isValidDurationFormat(duration)) {
    console.error('Duração inválida ou formato incorreto');
    return [];
  }

  const times = [];
  let [currentHour, currentMinute] = openingTime.split(':').map(Number);
  const [endHour, endMinute] = closingTime.split(':').map(Number);
  const [durationHours, durationMinutes] = duration.split(':').map(Number);
  const totalDurationMinutes = durationHours * 60 + durationMinutes;

  if (totalDurationMinutes <= 0) {
    console.error('Duração inválida');
    return [];
  }

  while (currentHour < endHour || (currentHour === endHour && currentMinute < endMinute)) {
    const formattedTime = `${String(currentHour).padStart(2, '0')}:${String(currentMinute).padStart(2, '0')}`;

    if (!breakStartTime || !breakEndTime || (formattedTime < breakStartTime || formattedTime >= breakEndTime)) {
      const isConflicting = schedules.some(schedule => {
        const scheduleStart = new Date(schedule.start_time).getTime();
        const scheduleEnd = new Date(schedule.end_time).getTime();
        const optionStart = new Date(`${startOfDay(new Date()).toISOString().split('T')[0]}T${formattedTime}:00`).getTime();
        const optionEnd = optionStart + totalDurationMinutes * 60 * 1000;
        return optionStart < scheduleEnd && optionEnd > scheduleStart;
      });

      if (!isConflicting) {
        times.push(formattedTime);
      }
    }

    currentMinute += totalDurationMinutes;
    if (currentMinute >= 60) {
      currentHour += Math.floor(currentMinute / 60);
      currentMinute %= 60;
    }

    if (currentHour > endHour || (currentHour === endHour && currentMinute > endMinute)) {
      break;
    }
  }

  return times;
};

const getAvailableEmployees = (employees, schedules, date, time) => {
  const selectedDate = format(date, 'yyyy-MM-dd');
  const weekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  const availableEmployees = employees.filter(employee => {
    const workingHours = employee.working_hours[weekDays[getDay(date)]];
    const openingTime = workingHours.open_time;
    const closingTime = workingHours.close_time;
    const breakStartTime = workingHours.break_start_time;
    const breakEndTime = workingHours.break_end_time;

    const isWithinWorkingHours = time >= openingTime && time < closingTime;
    const isDuringBreak = breakStartTime && breakEndTime && (time >= breakStartTime && time < breakEndTime);

    if (isWithinWorkingHours && !isDuringBreak) {
      return !checkIfScheduled(schedules, employee.id, selectedDate, time);
    }
    return false;
  });

  return availableEmployees;
};

const checkIfScheduled = (schedules, employeeId, date, time) => {
  const startTime = new Date(`${date}T${time}:00`);
  return schedules.some(schedule => {
    const scheduleStart = new Date(schedule.start_time);
    const scheduleEnd = new Date(schedule.end_time);
    return schedule.employee_id === employeeId && startTime >= scheduleStart && startTime < scheduleEnd;
  });
};

const hasAvailableEmployees = (employees, schedules, date, time, duration) => {
  const availableEmployees = getAvailableEmployees(employees, schedules, date, time);
  const [durationHours, durationMinutes] = duration.split(':').map(Number);
  const totalDurationMinutes = durationHours * 60 + durationMinutes;

  return availableEmployees.some(employee => {
    const startTime = new Date(`${date.toISOString().split('T')[0]}T${time}:00`);
    const endTime = new Date(startTime.getTime() + totalDurationMinutes * 60 * 1000);

    return !schedules.some(schedule => {
      const scheduleStart = new Date(schedule.start_time);
      const scheduleEnd = new Date(schedule.end_time);
      return schedule.employee_id === employee.id && (startTime < scheduleEnd && endTime > scheduleStart);
    });
  });
};

export default function DatePaginationPartner({ partnerSlug, selectedService, partnerData, duration, onTimeSelect }) {
  const [starDate] = useState(new Date());
  const [currentDate, setCurrentDate] = useState(startOfDay(starDate));
  const [showAllTimes, setShowAllTimes] = useState(false);
  const [showSchedules, setShowSchedules] = useState(true);
  const [selectedTime, setSelectedTime] = useState(null);
  const [daysToAdd, setDaysToAdd] = useState(6);

  const [formattedDate, setFormattedDate] = useState(format(starDate, 'yyyy-MM-dd'));
  const [formattedEndDate, setFormattedEndDate] = useState(format(addDays(starDate, daysToAdd), 'yyyy-MM-dd'));
  const { data: schedulesData, refetch, isError, isSuccess } = useDaysSchedulesPartnerSearchData(partnerSlug, selectedService, formattedDate, formattedEndDate);

  const weekDays = useMemo(() => ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'], []);
  const employees = schedulesData?.employees || [];

  useEffect(() => {
    if (schedulesData) {
      setSelectedTime(null);
    }
  }, [schedulesData]);

  useEffect(() => {
    if (isError) {
      setShowSchedules(false);
    }
    if (isSuccess){
      setShowSchedules(true);
    }
  }, [isError]);

  useEffect(() => {
    if (selectedService) {
      refetch();
      setSelectedTime(null);
      if (onTimeSelect) {
        onTimeSelect({ availableEmployees: null });
      }
    }
  }, [refetch, selectedService]);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      setDaysToAdd(windowWidth <= 991.98 ? 3 : 7);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handlePrevious = () => {
    const previousDate = subDays(currentDate, daysToAdd);
    
    setFormattedDate(format(previousDate, 'yyyy-MM-dd'));
    setFormattedEndDate(format(currentDate, 'yyyy-MM-dd'));

    setCurrentDate(previousDate);
  };
  const handleNext = () => {
    const nextDate = addDays(currentDate, daysToAdd);
    setCurrentDate(nextDate);

    setFormattedDate(format(nextDate, 'yyyy-MM-dd'));
    setFormattedEndDate(format(addDays(nextDate, daysToAdd), 'yyyy-MM-dd'));
  };

  useEffect(() => {
    if (formattedDate && formattedEndDate) {
      refetch();
    }
  }, [formattedDate, formattedEndDate, refetch]);


  const handleButtonClick = (date, time) => {
    if (hasAvailableEmployees(employees, schedulesData?.schedules, date, time, duration)) {
      const availableEmployees = getAvailableEmployees(employees, schedulesData?.schedules, date, time);
      setSelectedTime({ date, time });
      if (onTimeSelect) {
        onTimeSelect({ date, time, availableEmployees });
      }
    }
  };

  const renderDateColumns = () => {
    const columns = [];
    for (let i = 0; i < daysToAdd; i++) {
      const date = addDays(currentDate, i);
      const dayOfWeek = weekDays[getDay(date)];
      const dayData = partnerData ? partnerData[dayOfWeek] : null;
      const openTime = dayData?.open_time || "08:00";
      const closeTime = dayData?.close_time || "18:00";
      const breakStart = dayData?.has_break ? dayData.break_start_time : null;
      const breakEnd = dayData?.has_break ? dayData.break_end_time : null;
      const options = isValidDurationFormat(duration) ? generateTimeOptions(openTime, closeTime, breakStart, breakEnd, duration, schedulesData?.schedules) : [];
      const timesToShow = showAllTimes ? options : options.slice(0, 5);
  
      columns.push(
        <Col key={i}>
          <div className={styles.dateHeader}>
            <p className={styles.textDate}>{format(date, 'EEEEEE dd/MM')}</p>
          </div>
          {timesToShow.map((time, index) => {
            const isSelected = selectedTime?.date.getTime() === date.getTime() && selectedTime?.time === time;
            const hasEmployees = hasAvailableEmployees(employees, schedulesData?.schedules, date, time, duration);
            return (
              <Row key={index} className={styles.rowTimeOptions}>
                <Button
                  className={`${styles.buttonTime} ${!hasEmployees ? styles.buttonDisabled : ''} ${isSelected ? styles.buttonSelected : ''}`}
                  onClick={() => handleButtonClick(date, time)}
                  disabled={!hasEmployees}
                >
                  {time}
                </Button>
              </Row>
            );
          })}
        </Col>
      );
    }
    return columns;
  };

  return (
    <section id={styles.DatePaginationPartner}>
      <Container className={styles.container}>
          {showSchedules ?(
            <>
              <Row className={styles.rowButton}>
                <Col xs={1} sm={1} md={1} lg={1} xl={1} className={styles.colButtonBack}>
                  <Button onClick={handlePrevious} className={styles.button}>
                    <BiChevronLeft className={styles.iconButton}/>
                  </Button>
                </Col>
                {renderDateColumns()}
                <Col xs={1} sm={1} md={1} lg={1} xl={1} className={styles.colButtonBack}>
                  <Button onClick={handleNext} className={styles.button}>
                    <BiChevronRight className={styles.iconButton}/>
                  </Button>
                </Col>
              </Row>
              <Row className={styles.rowMoreLessButton} xs={12} sm={12} md={12} lg={12} xl={12}>
                <Button className={styles.buttonMoreTime} onClick={() => setShowAllTimes(!showAllTimes)}>
                  {showAllTimes ? <>menos <BiChevronLeft /></> : <>mais <BiChevronRight /></>}
                </Button>
              </Row>
            </>
          ): (
            <Row className={styles.rowNoEmployee}>
              <p className={styles.text}> Entre em contato com o profissional para obter mais detalhes sobre o serviço.<br/>
                <br/>Lembre-se: exija o pagamento através da Goinguru, se você desejar nosso suporte contra golpes financeiros. A Goinguru não realiza negociações de pagamento por fora da plataforma.
              </p>
              <p className={styles.text}>Obrigado!</p>
            </Row>
          )}
      </Container>
    </section>
  );
}
