import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Image, Card, Spinner, Alert, Modal } from 'react-bootstrap';
import styles from "./style.module.scss"
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useAuthContext } from '../../contexts/authContext';
import NoImage from "../../assets/no-image.jpg";
import CustomProgressBar from '../../components/progressBar';
import { useNavigate, useParams } from 'react-router-dom';
import { useOneClientRequestsDetailsData, usePatchOneRequestPaymentClient } from '../../api/userAPI/userClient';
import { toast } from 'react-toastify';

export default function ClientRequestsDetailsPage() {
    const { requestSlug } = useParams();
    const { token } = useAuthContext();
    const [currentStep, setCurrentStep] = useState(1);
    
    const navigate = useNavigate();
    
    const handleCheckoutRedirect = () => {
        navigate(`/checkout/${requestSlug}/`);
    };
    
    const handleBack = () => {
        navigate(`/meusPedidos/`);
    };
    
    const { ClientRequestData, isError: isErrorClient, isLoading: isLoadingClient } = useOneClientRequestsDetailsData(token, requestSlug);
    const { mutate: patchOneRequestPaymentClient, isSuccess: isSuccessPatch, isError: isErrorPatch } = usePatchOneRequestPaymentClient(requestSlug, token);
   
    const [isLoadingButton, setIsLoadingButton] = useState(false);

    const [showCancelConfirmationModal, setShowCancelConfirmationModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showFinishedModal, setShowFinishedModal] = useState(false);
    const [showHelpModal, setShowHelpModal] = useState(false);

    const handleCancelConfirmationModalShow = () => setShowCancelConfirmationModal(true);
    const handleCancelConfirmationModalClose = () => setShowCancelConfirmationModal(false);

    const handleCancelModalShow = () => setShowCancelModal(true);
    const handleCancelModalClose = () => setShowCancelModal(false);

    const handleHelpModalShow = () => setShowHelpModal(true);
    const handleHelpModalClose = () => setShowHelpModal(false);

    const handleFinishedModalShow = () => setShowFinishedModal(true);
    const handleFinishedModalClose = () => setShowFinishedModal(false);

    const handleConfirmCancel = () => {
        setIsLoadingButton(true);
        patchOneRequestPaymentClient({ requestSlug, token });
        setShowCancelConfirmationModal(false);
        setShowFinishedModal(false);
    };
    
    const steps = [
        'Pedido Realizado',
        currentStep === 2
            ? ClientRequestData?.status === 'paid'
                ? 'Pagamento Aprovado'
                : (ClientRequestData?.status === 'rejected' || ClientRequestData?.status === 'failed')
                    ? 'Pagamento com Falha'
                    : 'Aguardando Pagamento'
            : 'Aguardando Pagamento',
        ClientRequestData?.status === 'cancelled_by_c'
            ? 'Cancelamento Cliente'
            : ClientRequestData?.status === 'cancelled_by_p'
                ? 'Cancelamento Parceiro'
                : ClientRequestData?.status === 'finished'
                    ? 'Concluído'
                    : 'Finalizado'
    ];
    
    const mapStatusToStep = (status) => {
        switch (status) {
            case 'pending':
                return 1;
            case 'paid':
            case 'refunded':
            case 'rejected':
            case 'failed':
                return 2;
            case 'completed':
            case 'cancelled_by_c':
            case 'cancelled_by_p':
            case 'finished':
                return 3;
            default:
                return 1;
        }
    }; 

    useEffect(() => {
        if(ClientRequestData){
            setCurrentStep(mapStatusToStep(ClientRequestData.status));
        }
    }, [ClientRequestData]);

    useEffect(() => {
        if (isSuccessPatch) {
            toast.success('Serviço atualizado com sucesso!');
            setIsLoadingButton(false);
        } 
        if (isErrorPatch) {
            toast.error('Erro ao atualizar serviço, tente novamente ou entre em contato com o suporte!');
            setIsLoadingButton(false);
        }
    }, [isSuccessPatch, isErrorPatch]);

    if (isLoadingClient) {
        return (
            <section id={styles.ClientScheduleDetailsPage}>
                <Container>
                    <Row>
                        <Col className="text-center">
                            <Spinner animation="border" role="status">
                            </Spinner>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }

    if (isErrorClient) {
        return (
            <section id={styles.ClientScheduleDetailsPage}>
                <Container>
                    <Row>
                        <Col>
                            <Alert variant="danger">
                                Erro ao carregar os dados. Por favor, tente novamente mais tarde.
                            </Alert>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }

    const orderNumber = ClientRequestData?.orderNumber;
    const lastNumber = orderNumber ? orderNumber.split('-').pop() : '';

    return (
        <HelmetProvider>
            <section id={styles.ClientScheduleDetailsPage}>
                <Helmet>
                    <title>Agendamento - GoinGuru</title>
                    <meta name="description" content="GoinGuru" />
                </Helmet>
                <Container>
                    <Row className={styles.rowTitle}>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <p className={styles.title}>Detalhes do Agendamento -  Nº {lastNumber}</p>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Row className={styles.rowButton}>
                                <Col className={styles.colButtonCancelar}>
                                    <Button className={styles.buttonCancelar} onClick={handleBack} >Voltar</Button>
                                </Col>
                                <Col className={styles.colButton}>
                                    <Button className={styles.button} onClick={handleHelpModalShow}>Preciso de Atendimento</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className={styles.rowPayment}>
                        <Card className={styles.cardPaymentSchedule}>
                            <p className={styles.paymentTitle}>Status</p>
                            <Row className={styles.rowProgressBar}>
                                <CustomProgressBar steps={steps} currentStep={currentStep} />
                            </Row>
                            {currentStep >= 2 && (
                                <>
                                    <Row className={styles.rowPaymentText}>
                                        <Col>
                                            <p className={styles.paymentSubText}>Forma de pagamento:</p>
                                            <p className={styles.paymentSubText}>
                                                {ClientRequestData?.last_payment?.payment_data?.payment_method?.type} {ClientRequestData?.last_payment?.payment_data?.payment_method?.id}
                                            </p>
                                        </Col>
                                        <Col>
                                            <p className={styles.paymentSubText}>{ClientRequestData?.last_payment?.payment_data?.card?.first_six_digits}********{ClientRequestData?.last_payment?.payment_data?.card?.last_four_digits}</p>
                                            <p className={styles.paymentSubText}>a vista de {formatPrice(ClientRequestData?.last_payment?.payment_data?.transaction_amount)}</p>
                                        </Col>
                                    </Row>
                                    {ClientRequestData?.status === "paid" && ClientRequestData?.status !== "refunded" && (
                                        <Row className={styles.rowCancelSchedule}>
                                            <Col className={styles.colCancelSchedule}>
                                                <Button 
                                                    className={styles.buttonCancelSchedule} onClick={handleCancelModalShow}>
                                                    Solicitar Cancelamento
                                                </Button>
                                            </Col>
                                            <Col className={styles.colFishedSchedule}>
                                                {ClientRequestData?.client_confirmation ? (
                                                    <Button 
                                                        className={styles.buttonFinishedSchedule} 
                                                        disabled
                                                    >
                                                        Serviço já Confirmado
                                                    </Button>
                                                ) : (
                                                    <Button 
                                                        className={styles.buttonFinishedSchedule} 
                                                        onClick={handleFinishedModalShow}
                                                    >
                                                        Finalizar Serviço
                                                    </Button>
                                                )}
                                            </Col>
                                        </Row>
                                    )}
                                    {ClientRequestData?.client_confirmation === true && currentStep === 2 &&
                                        <Row className={styles.rowCancelSchedule}>
                                            <p className={styles.paymentSubText}>Pendente confirmação de serviço finalizado Parceiro Guru!</p>
                                        </Row>
                                    }
                                    {ClientRequestData?.partner_confirmation === true && currentStep === 2 &&
                                        <Row className={styles.rowCancelSchedule}>
                                            <p className={styles.paymentSubText}>Serviço já finalizado pelo Parceiro Guru, confirme a finalização do pedido!</p>
                                        </Row>
                                    }
                                </>
                            )}
                            {(currentStep === 1 || currentStep === 2) && ClientRequestData?.status !== "paid" && ClientRequestData?.status !== "refunded" && (
                                <Row className={styles.rowCheckoutButton}>
                                    <Col className={styles.colButtonCancelar}>
                                        <Button className={styles.buttonCancelar} onClick={handleCancelConfirmationModalShow} disabled={isLoadingButton}>
                                            {isLoadingButton ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Solicitar Cancelamento"}
                                        </Button>
                                    </Col>
                                    <Col className={styles.colButtonCheckout}>
                                        <Button 
                                            onClick={handleCheckoutRedirect} 
                                            className={styles.buttonCheckout}>
                                            Ir para o Checkout
                                        </Button>
                                    </Col>
                                </Row>
                            )}
                            {currentStep === 3 && (
                                <Row className={styles.rowCancelSchedule}>
                                    <Col>
                                        {ClientRequestData?.status === 'cancelled_by_c' ? (
                                            <p className={styles.paymentSubText}>Pedido Cancelado pelo Cliente, para mais informações contate o suporte!</p>
                                        ) : ClientRequestData?.status === 'cancelled_by_p' ? (
                                            <p className={styles.paymentSubText}>Pedido Cancelado pelo Guru Parceiro, para mais informações contate o suporte!</p>
                                        ) : (
                                            <p className={styles.paymentSubText}>Finalizado! A GoinGuru agradece a preferência.</p>
                                        )}
                                    </Col>
                                </Row>
                            )}
                        </Card>
                    </Row>
                    <Row>
                        <Card className={styles.cardClientSchedule}>
                            <Row className={styles.rowClientSchedule}>
                                <Col xs={3} sm={4} md={3} lg={2} xl={2} className={styles.colPartnerSchedule}>
                                    <Image
                                        src={ClientRequestData?.serviceProvided?.photo || NoImage}
                                        className={styles.image}
                                    />
                                </Col>
                                <Col xs={9} sm={8} md={7} lg={8} xl={8} className={styles.colServiceSchedule}>
                                    <p><strong>Serviço:</strong> {ClientRequestData?.name}</p>
                                    <Row>
                                        <p className={styles.subText}>
                                            <strong>Preço:</strong> {ClientRequestData?.serviceProvided?.is_custom_quote ? 'A Combinar' : (ClientRequestData?.price ? formatPrice(ClientRequestData?.price) : '-')}
                                        </p>
                                    </Row>
                                    <p><strong>Observações:</strong> {ClientRequestData?.description}</p>
                                    <p className={styles.subText}>
                                        <strong>Tipo do serviço: </strong> 
                                        {ClientRequestData?.serviceProvided?.is_home_service
                                                ? 'Domicílio'
                                                : ClientRequestData?.serviceProvided?.is_online_service
                                                ? 'Online'
                                                : 'No Local'}
                                    </p>
                                </Col>
                                <Col xs={12} sm={12} md={2} lg={2} xl={2} className={styles.colEmployeeSchedule}>
                                    <p><strong>Colaborador:</strong> {ClientRequestData?.employee?.name?.split(' ')[0]}</p>
                                </Col>
                            </Row>
                            <Row className={styles.rowPartnerSchedule}>
                                <Col xs={3} sm={3} md={2} lg={2} xl={2} className={styles.colPartnerSchedule}>
                                    <Image
                                        src={ClientRequestData?.partner?.photo || NoImage}
                                        className={styles.image}
                                    />
                                </Col>
                                <Col xs={9} sm={9} md={10} lg={10} xl={10}>
                                    <p><strong>Empresa:</strong> {ClientRequestData?.partner?.name}</p>
                                    <Row>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            {ClientRequestData?.partner?.is_cnpj ? (
                                                <p><strong>CNPJ:</strong> {ClientRequestData?.partner?.cnpj}</p>
                                            ) : (
                                                <p><strong>CPF:</strong> {ClientRequestData?.partner?.cpf}</p>
                                            )}
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <p><strong>WhatsApp:</strong> {ClientRequestData?.partner?.whatsapp}</p>
                                        </Col>
                                    </Row>
                                    {ClientRequestData?.is_published_address ? (
                                        <p><strong>Endereço:</strong> {ClientRequestData?.partner?.a_street_name}, Nº {ClientRequestData?.partner?.a_number} - {ClientRequestData?.partner?.a_neighborhood} - {ClientRequestData?.partner?.a_city} / {ClientRequestData?.partner?.a_state} - {ClientRequestData?.partner?.a_postal_code}</p>
                                    ) : (
                                        <p><strong>Endereço:</strong> {ClientRequestData?.partner?.a_city} / {ClientRequestData?.partner?.a_state}</p>
                                    )}
                                </Col>
                            </Row>
                        </Card>
                    </Row>
                </Container>
                {/* Modal de Confirmação de Serviço */}
                <Modal show={showFinishedModal} onHide={handleFinishedModalClose} centered className={styles.modal}>
                    <Modal.Header closeButton>
                        <Modal.Title className={styles.title}>Confirmar Pedido</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Você tem certeza de que deseja confirmar o pedido, concordando com a entrega/prestação do serviço?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className={styles.buttonCancel} onClick={handleFinishedModalClose}>
                            Fechar
                        </Button>
                        <Button className={styles.buttonSave} onClick={handleConfirmCancel}>
                            Confirmar Pedido
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Modal de Confirmação de Cancelamento */}
                <Modal show={showCancelConfirmationModal} onHide={handleCancelConfirmationModalClose} centered className={styles.modal}>
                    <Modal.Header closeButton>
                        <Modal.Title className={styles.title}>Confirmar Cancelamento</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Você tem certeza de que deseja cancelar este pedido?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className={styles.buttonCancel} onClick={handleCancelConfirmationModalClose}>
                            Fechar
                        </Button>
                        <Button className={styles.buttonSave} onClick={handleConfirmCancel}>
                            Confirmar Cancelamento
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Modal de Cancelamento */}
                <Modal show={showCancelModal} onHide={handleCancelModalClose} centered className={styles.modal}>
                    <Modal.Header closeButton>
                        <Modal.Title className={styles.title}>Solicitação de Cancelamento</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Para cancelar o pedido, entre em contato com nosso atendimento ao cliente via chat, por número de WhatsApp +55 (11) 99000-5901 ou envie um email para suporte@goinguru.com.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className={styles.buttonCancel} onClick={handleCancelModalClose}>
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Modal de Atendimento */}
                <Modal show={showHelpModal} onHide={handleHelpModalClose} centered className={styles.modal}>
                    <Modal.Header closeButton>
                        <Modal.Title className={styles.title}>Precisa de Atendimento?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Se você precisa de ajuda, por favor, entre em contato com nosso atendimento ao cliente via chat, por email suporte@goinguru.com ou pelo número +55 (11) 99000-5901.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className={styles.buttonCancel} onClick={handleHelpModalClose}>
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </section>
        </HelmetProvider>
    );
}

const formatPrice = (value) => {
    if (!value) return '-';
    const number = parseFloat(value);
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(number);
};
