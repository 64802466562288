import React, { useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import styles from "./style.module.scss";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import PaymentCardForm from '../../components/checkoutCard';
import { FaCreditCard } from 'react-icons/fa';
import { FaPix } from "react-icons/fa6";
import PaymentPixForm from '../../components/checkoutPix';
import { useOneClientRequestsDetailsData } from '../../api/userAPI/userClient';
import { useAuthContext } from '../../contexts/authContext';
import { useParams } from 'react-router-dom';

export default function CheckoutPage() {
  const { request_id } = useParams();
  const { token } = useAuthContext();
  const [selectedPayment, setSelectedPayment] = useState('');
  const { ClientRequestData } = useOneClientRequestsDetailsData(token, request_id);

  const handlePaymentSelection = (paymentType) => {
    setSelectedPayment(paymentType);
  };

  return (
    <HelmetProvider>
      <section id={styles.CheckoutPage}>
        <Helmet>
          <title>Home - GoinGuru</title>
          <meta name="description" content="GoinGuru" />
        </Helmet>
        <Container className={styles.container}>
          <Row className={styles.rowTitle}>
            <p className={styles.title}>Informações de Pagamento</p>
          </Row>
          <Row className={styles.rowCard}>
            <Col xs={6} sm={6} md={4} lg={4} xl={4}>
              <Card
                onClick={() => handlePaymentSelection('card')}
                className={`${styles.card} ${selectedPayment === 'card' ? styles.active : ''}`}
              >
                <Row className={styles.rowIcon}>
                  <p className={styles.title}><FaCreditCard className={styles.icon}/> Cartão</p>
                </Row>
              </Card>
            </Col>
            <Col xs={6} sm={6} md={4} lg={4} xl={4}>
              <Card
                onClick={() => handlePaymentSelection('pix')}
                className={`${styles.card} ${selectedPayment === 'pix' ? styles.active : ''}`}
              >
                <Row className={styles.rowIcon}>
                    <p className={styles.title}><FaPix className={styles.icon}/> Pix</p>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row className={styles.rowCardDetails}>
            <Col xs={12} sm={12} md={8} lg={8} xl={8}>
              {selectedPayment === 'card' && <PaymentCardForm  ClientRequestData={ClientRequestData} />}
              {selectedPayment === 'pix' && <PaymentPixForm  ClientRequestData={ClientRequestData}/>}
            </Col>
            <Col>
              <Card className={styles.card}>
                <Row className={styles.rowText}>
                  <p className={styles.title}>Detalhes do Pedido: <br/>{ClientRequestData?.orderNumber}</p>
                </Row>
                <Row className={styles.rowText}>
                  <p className={styles.text}>{ClientRequestData?.name}</p>
                  <p className={styles.text}>{ClientRequestData?.description}</p>
                  <p className={styles.price}>{formatPrice(ClientRequestData?.price)}</p>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </HelmetProvider>
  );
}

const formatPrice = (value) => {
  if (!value) return '-';
  const number = parseFloat(value);
  return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(number);
};
