import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import styles from "./style.module.scss";
import { SignupAPI } from '../../api/signup';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from 'react-toastify';
import MaskedInput from 'react-text-mask';
import { isValidCPF } from '../../utils/utils';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

export default function SectionSignUp() {
    const [formValues, setFormValues] = useState({
        name: '',
        birth: '',
        email: '',
        cpf: '',
        password: '',
        confirmPassword: '',
    });
    const [validated, setValidated] = useState(false);
    const [captchaValue, setCaptchaValue] = useState(null);
    const [showRecaptcha, setShowRecaptcha] = useState(false);
    const [receiveCommunications, setReceiveCommunications] = useState(false);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState({
        password: false,
        confirmPassword: false,
    });

    const toggleShowPassword = (field) => {
        setShowPassword((prevState) => ({
            ...prevState,
            [field]: !prevState[field],
        }));
    };

    const cpfMask = [/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];

    useEffect(() => {
        setShowRecaptcha(
            formValues.name !== '' &&
            formValues.birth !== '' &&
            formValues.email !== '' &&
            formValues.cpf !== '' &&
            formValues.password !== ''
        );
    }, [formValues]);
    

    const handleLogin = () => {
        navigate('/login');
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formValues.name) newErrors.name = "Nome completo é obrigatório.";
        if (!formValues.email) newErrors.email = "E-mail é obrigatório.";
        if (!formValues.cpf) {
            newErrors.cpf = "CPF é obrigatório.";
        } else if (!isValidCPF(formValues.cpf.replace(/\D/g, ''))) {
            newErrors.cpf = "CPF inválido.";
        }
        if (!formValues.password) newErrors.password = "Senha é obrigatória.";
        if (!formValues.confirmPassword) {
            newErrors.confirmPassword = "Confirmação de senha é obrigatória.";
        } else if (formValues.password !== formValues.confirmPassword) {
            newErrors.confirmPassword = "As senhas não coincidem.";
        }
        if (!formValues.birth) newErrors.birth = "Data de nascimento é obrigatória.";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (!validateForm()) {
            setValidated(true);
            return;
        }

        if (!captchaValue) {
            toast.error('Por favor, resolva o reCAPTCHA!');
            return;
        }

        try {
            const response = await SignupAPI.signup(formValues.name, formValues.email, formValues.password, formValues.birth, formValues.cpf);

            if (response.status === 200) {
                toast.success('Cadastro realizado com sucesso!');
                navigate('/login');
            } else if (response.status === 400) {
                toast.error('Erro ao fazer cadastro, email já utilizado!');
            } else if (response.status === 404) {
                toast.error('Erro ao fazer cadastro, verifique o email e senha!');
            }
        } catch (error) {
            console.error('Erro ao fazer cadastro:', error.message);
            toast.error('Erro ao fazer cadastro, verifique os dados e tente novamente!');
        }
    };

    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
    };

    const handleOpenTerms = () => {
        window.open('/termos-e-condicoes', '_blank');
    };
    
    const handleOpenPolicy = () => {
        window.open('/politica-de-privacidade', '_blank');
    };

    return (
        <section id={styles.SectionLogin}>
            <Container className={styles.containerLogin}>
                <Row className="justify-content-center">
                    <Col xs={12} md={6}>
                        <Row className={styles.rowTitle}>
                            <p>Crie sua conta</p>
                        </Row>
                        <Row className={styles.rowForm}>
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <Form.Group controlId="name">
                                    <Form.Label className={styles.formLabel}>Nome Completo</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Digite seu nome"
                                        className={styles.formControl2}
                                        name="name"
                                        value={formValues.name}
                                        onChange={handleInputChange}
                                        maxLength="120"
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="email">
                                    <Form.Label className={styles.formLabel}>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Digite seu email"
                                        className={styles.formControl2}
                                        name="email"
                                        value={formValues.email}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="password">
                                    <Form.Label className={styles.formLabel}>Senha</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            type={showPassword.password ? "text" : "password"}
                                            className={styles.formControl}
                                            placeholder="Digite sua senha"
                                            name="password"
                                            value={formValues.password}
                                            onChange={handleInputChange}
                                            isInvalid={!!errors.newPassword}
                                        />
                                        <Button
                                            onClick={() => toggleShowPassword('password')}
                                            className={styles.showPasswordButton}
                                        >
                                            {showPassword.password ? <FaEyeSlash /> : <FaEye />}
                                        </Button>
                                        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group controlId="confirmPassword">
                                    <Form.Label className={styles.formLabel}>Confirme a senha</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            type={showPassword.confirmPassword ? "text" : "password"}
                                            className={styles.formControl}
                                            placeholder="Confirme sua senha"
                                            name="confirmPassword"
                                            value={formValues.confirmPassword}
                                            onChange={handleInputChange}
                                            isInvalid={!!errors.confirmPassword}

                                        />
                                        <Button
                                            onClick={() => toggleShowPassword('confirmPassword')}
                                            className={styles.showPasswordButton}
                                        >
                                            {showPassword.confirmPassword ? <FaEyeSlash /> : <FaEye />}
                                        </Button>
                                        <Form.Control.Feedback type="invalid">{errors.confirmPassword}</Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group controlId="cpf">
                                    <Form.Label className={styles.formLabel}>CPF</Form.Label>
                                    <MaskedInput
                                        mask={cpfMask}
                                        className={styles.formControl2 + " form-control"}
                                        name="cpf"
                                        value={formValues.cpf}
                                        onChange={handleInputChange}
                                        render={(ref, props) => (
                                            <Form.Control
                                                ref={ref}
                                                {...props}
                                                isInvalid={!!errors.cpf}
                                            />
                                        )}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.cpf}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="birth">
                                    <Form.Label className={styles.formLabel}>Data de Nascimento</Form.Label>
                                    <Form.Control
                                        type="date"
                                        placeholder="Digite sua data de nascimento"
                                        className={styles.formControl2}
                                        name="birth"
                                        value={formValues.birth}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.birth}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="formBasicCheckbox" className={styles.formCheckbox}>
                                    <Form.Check 
                                        type="checkbox" 
                                        label="Gostaria de receber comunicações comerciais da Goinguru (opcional)." 
                                        checked={receiveCommunications}
                                        onChange={(e) => setReceiveCommunications(e.target.checked)}
                                    />
                                </Form.Group>
                                {showRecaptcha && (
                                    <Row className={styles.rowRecaptcha}>
                                        <ReCAPTCHA
                                            sitekey="6LeaPe0pAAAAAG44B5AzphXhmL8IKsmJ9QUTjTsF"
                                            onChange={handleCaptchaChange}
                                            className={styles.recaptcha}
                                        />
                                    </Row>
                                )}
                                <Button className={styles.button} type="submit">Cadastrar</Button>
                            </Form>
                        </Row>
                        <Row className={styles.rowTerms}>
                            <p className={styles.termsText}>
                                Ao se registrar, você está de acordo com nossos 
                                <span onClick={handleOpenTerms} className={styles.link}> termos e condições</span>, 
                                e confirma estar ciente de nossa 
                                <span onClick={handleOpenPolicy} className={styles.link}> política de privacidade</span>.
                            </p>
                        </Row>
                        <Row className={styles.rowSignUp}>
                            <p className={styles.signUpText}>Já tem conta? <strong onClick={handleLogin}>Faça login</strong></p>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
