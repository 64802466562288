import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import React from 'react';
import { ProtectedRoute } from '../contexts/protectedRoute';
import Menu from '../components/menu';
import Footer from '../components/footer';
import NotFoundPage from '../pages/notFound';
import HomePage from '../pages/home';
import LoginPage from '../pages/login';
import BlogPage from '../pages/blog';
import PostBlogPage from '../pages/blog/blogPage';
import SignUpPage from '../pages/signup';
import { AuthProvider } from '../contexts/authContext';
import { Roles } from '../api/default';
import ProfilePage from '../pages/profile';
import SearchPage from '../pages/search';
import ClientSchedulesPage from '../pages/clientSchedules';
import PartnerPage from '../pages/partner';
import RecoverPasswordPage from '../pages/recoverPassword';
import ChangePasswordPage from '../pages/changePassword';
import ClientScheduleDetailsPage from '../pages/clientSchedulesDetails';
import CheckoutPage from '../pages/checkOut';
import ClientRequestsPage from '../pages/clientRequests';
import ClientRequestsDetailsPage from '../pages/clientRequestsDetails';
import AboutPage from '../pages/about';
import TopsDaCidadePage from '../pages/topsDaCidade';
import TermosCondicoesPage from '../pages/termosCondicoes';
import PoliticasCookiesPage from '../pages/cookies';


const AppLayout = () => (
    <>
        <Menu />
        <Outlet />
        <Footer />
    </>
);

export default function DefaultRoutes() {
    return (
        <BrowserRouter>
            <AuthProvider>
                <Routes>
                    <Route element={<AppLayout />}>
                        <Route path='/' element={<HomePage />} />
                        <Route path='/login' element={<LoginPage />} />
                        <Route path='/signup' element={<SignUpPage />} />
                        <Route path='/termos-e-condicoes' element={<TermosCondicoesPage />} />
                        <Route path='/politica-de-privacidade' element={<PoliticasCookiesPage />} />
                        <Route path='/recoverPassword' element={<RecoverPasswordPage />} />
                        <Route path='/blog' element={<BlogPage />} />
                        <Route path='/blog/post/:postSlug' element={<PostBlogPage />} />
                        <Route path='/sobre' element={<AboutPage />} />
                        <Route path='/topsDaCidade' element={<TopsDaCidadePage />} />
                        <Route path='/pesquisa' element={<SearchPage />} />
                        <Route path='/parceiroGuru/:partnerSlug' element={<PartnerPage />} />
                        <Route path='/meuPerfil' element={<ProtectedRoute roles={[Roles.CLIENTE_GURU, Roles.PARCEIRO_A_GURU]}> <ProfilePage /> </ProtectedRoute>} />
                        <Route path='/changePassword' element={<ProtectedRoute roles={[Roles.CLIENTE_GURU, Roles.PARCEIRO_A_GURU]}> <ChangePasswordPage /> </ProtectedRoute>} />
                        <Route path='/meusPedidos' element={<ProtectedRoute roles={[Roles.CLIENTE_GURU, Roles.PARCEIRO_A_GURU]}> <ClientRequestsPage /> </ProtectedRoute>} />
                        <Route path='/meusPedidos/:requestSlug' element={<ProtectedRoute roles={[Roles.CLIENTE_GURU, Roles.PARCEIRO_A_GURU]}> <ClientRequestsDetailsPage /> </ProtectedRoute>} />
                        <Route path='/agendamento/:schedule_id' element={<ProtectedRoute roles={[Roles.CLIENTE_GURU, Roles.PARCEIRO_A_GURU]}> <ClientScheduleDetailsPage /> </ProtectedRoute>} />
                        <Route path='/checkout/:request_id' element={<ProtectedRoute roles={[Roles.CLIENTE_GURU, Roles.PARCEIRO_A_GURU]}> <CheckoutPage /> </ProtectedRoute>} />
                    </Route>
                    <Route path='*' element={<NotFoundPage />} />
                </Routes>
            </AuthProvider>
        </BrowserRouter>
    )
}
