import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import styles from "./style.module.scss"
import { AiFillFacebook, AiFillInstagram, AiFillLinkedin, AiFillTwitterSquare, AiFillYoutube } from 'react-icons/ai';
import logoGuru from "../../assets/logoBranco.png"
import certificado from "../../assets/cardFooter.png"
import { Link } from 'react-router-dom';

export default function Footer() {
    const openUrlInNewTab = (url) => {
        window.open(url, '_blank');
    };

    const whatsappMessageText = () => 
        `Olá, quero informações sobre um serviço divulgado na GoinGuru.`;
      
    const handleWhatsappClick = (clientWhatsapp) => {
    
        if (clientWhatsapp) {
        const cleanedWhatsapp = clientWhatsapp.replace(/\D/g, '').slice(-11);
        const message = whatsappMessageText();
        const whatsappUrl = `https://wa.me/55${cleanedWhatsapp}?text=${encodeURIComponent(message)}`;
        window.open(whatsappUrl, '_blank');
    }
    };

    return (
        <section id={styles.Footer}>
            <Container>
                <Row>
                    <Col xs={12} md={9}>
                        <Row>
                            <Col xs={12} md={4}>
                                <Row className={styles.rowText}>
                                    <p className={styles.title}>Serviços</p>
                                    <Link to="/politica-de-privacidade" className={styles.text}>Privacidade e cookies</Link>
                                    <Link to="/termos-e-condicoes" className={styles.text}>Termos e condições</Link>
                                    <p className={styles.text}>Contato</p>
                                </Row>
                            </Col>
                            <Col xs={12} md={4}>
                                <Row className={styles.rowText}>
                                    <p className={styles.title}>Suporte</p>
                                    <p className={styles.text}>Atendimento via Chat</p>
                                    <p className={styles.text}>9:00 - 18:00 / Segunda a Sábado</p>
                                    <p className={styles.text}>suporte@goinguru.com</p>
                                    <p className={styles.textClick} onClick={() => handleWhatsappClick('11990005901')}>+55 (11) 99000-5901</p>
                                </Row>
                            </Col>
                            <Col xs={12} md={4}>
                                <Row className={styles.rowText}>
                                    <p className={styles.title}>Menu</p>
                                    <Link to="/" className={styles.text}>Início</Link>
                                    <Link to="/sobre" className={styles.text}>Sobre Nós</Link>
                                    <a href="https://parceiro.goinguru.com" target="_blank" rel="noopener noreferrer" className={styles.text}>Parceiro</a>
                                    <Link to="/topsDaCidade" className={styles.text}>Tops da Cidade 2024</Link>
                                    <Link to="/blog" className={styles.text}>Blog</Link>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={3}>
                        <Row className={styles.rowText}>
                            <p className={styles.title}>Redes Sociais</p>
                        </Row>
                        <Row className={styles.rowIcon}>
                            <AiFillFacebook
                                className={styles.icon}
                                onClick={() => openUrlInNewTab("https://www.facebook.com/goinguru.of")}
                            />
                            <AiFillYoutube
                                className={styles.icon}
                                onClick={() => openUrlInNewTab("https://www.youtube.com/")}
                            />
                            <AiFillInstagram
                                className={styles.icon}
                                onClick={() => openUrlInNewTab("https://www.instagram.com/goinguru")}
                            />
                            <AiFillTwitterSquare
                                className={styles.icon}
                                onClick={() => openUrlInNewTab("https://www.x.com/goinguru")}
                            />
                            <AiFillLinkedin
                                className={styles.icon}
                                onClick={() => openUrlInNewTab("https://www.linkedin.com")}
                            />
                        </Row>
                        <Row className={styles.rowLogo}>
                            <Image src={logoGuru} className={styles.logo}/>
                        </Row>
                    </Col>
                </Row>
                <Row className={styles.rowDireitos}>
                    {/* <Image src={certificado} className={styles.image}/> */}
                    <p className={styles.text}><Image src={certificado} className={styles.image}/> www.goinguru.com.br © 2024 - Encontre o seu profissional agora</p>
                </Row>
            </Container>
        </section>
    );
}
