import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import styles from "./style.module.scss";
import { useAuthContext } from '../../contexts/authContext';
import { BASE_URL } from "../../api/default";

const PaymentPixForm = ( {ClientRequestData} ) => {
  const { token } = useAuthContext();
  const [qrCode, setQrCode] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    const firstName = document.getElementById('form-checkout__payerFirstName').value;
    const lastName = document.getElementById('form-checkout__payerLastName').value;
    const email = document.getElementById('form-checkout__email').value;
    const identificationType = document.getElementById('form-checkout__identificationType').value;
    const identificationNumber = document.getElementById('form-checkout__identificationNumber').value;

    try {
      if (!window.MercadoPago) {
        const script = document.createElement('script');
        script.src = "https://sdk.mercadopago.com/js/v2";
        script.onload = () => initializePixPayment();
        document.body.appendChild(script);
      } else {
        initializePixPayment();
      }

      async function initializePixPayment() {
        const amountValue = ClientRequestData?.price ? ClientRequestData?.price.toString() : '0.00';

        const response = await fetch(`${BASE_URL}/payment/process-payment-pix/`, {
          method: 'POST',
          headers: {
            jwt: token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            client_order_id: ClientRequestData.id,
            transaction_amount: amountValue,
            description: ClientRequestData.description,
            payment_method_id: "pix",
            payer: {
              email: email,
              first_name: firstName,
              last_name: lastName,
              identification: {
                type: identificationType,
                number: identificationNumber,
              },
            },
          }),
        });

        if (!response.ok) {
          throw new Error('Erro ao criar pagamento via Pix');
        }

        const data = await response.json();
        const transactionData = data?.qr_code_base64;
        if (transactionData) {
          setQrCode(transactionData);
        } else {
          throw new Error('Dados de transação ausentes ou inválidos.');
        }

        setLoading(false);
      }
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  return (
    <section id={styles.CheckoutPage}>
      <Container className={styles.checkoutContainer}>
        <Row>
          {!qrCode && (
            <Form id="form-checkout" onSubmit={handleSubmit}>
              <Card className={styles.card}>
                <Card.Title className={styles.cardTitle}>Informações do Cliente</Card.Title>
                <Row>
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Form.Group controlId="form-checkout__payerFirstName">
                      <Form.Label className={styles.formLabel}>Nome</Form.Label>
                      <Form.Control className={styles.formControl} type="text" required />
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Form.Group controlId="form-checkout__payerLastName">
                      <Form.Label className={styles.formLabel}>Sobrenome</Form.Label>
                      <Form.Control className={styles.formControl} type="text" required />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Form.Group controlId="form-checkout__email">
                    <Form.Label className={styles.formLabel}>E-mail</Form.Label>
                    <Form.Control className={styles.formControl} type="email" required />
                  </Form.Group>
                </Row>
                <Row>
                  <Col xs={12} sm={5} md={5} lg={5} xl={5}>
                    <Form.Group controlId="form-checkout__identificationType">
                      <Form.Label className={styles.formLabel}>Tipo de documento</Form.Label>
                      <Form.Control className={styles.formControl} as="select" required>
                        <option value="CPF">CPF</option>
                        {/* Outros tipos de documentos podem ser preenchidos aqui */}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={7} md={7} lg={7} xl={7}>
                    <Form.Group controlId="form-checkout__identificationNumber">
                      <Form.Label className={styles.formLabel}>Número do documento</Form.Label>
                      <Form.Control className={styles.formControl} type="text" required />
                    </Form.Group>
                  </Col>
                </Row>

                <input type="hidden" name="transactionAmount" id="transactionAmount" value="100" />
                <input type="hidden" name="description" id="description" value="Nome do Produto" />
                <br />
                <Row className={styles.rowButton}>
                  <Button type="submit" className={styles.button}>
                    {loading ? "Processando..." : "Pagar"}
                  </Button>
                </Row>
              </Card>
            </Form>
          )}

          {loading && <p>Carregando QR Code...</p>}
          {error && <p>Erro: {error}</p>}

          {/* Exibe o QR Code se ele for gerado */}
          {!loading && qrCode && (
            <Card className={styles.card}>
              <Card.Body>
                <Row className="justify-content-center">
                  <Col xs={12} sm={12} md={8} lg={6} xl={6} className="text-center">
                    <p className={styles.cardTitle}>Escaneie o código QR abaixo para realizar o pagamento via Pix:</p>
                    <img src={`data:image/png;base64,${qrCode}`} alt="QR Code Pix" className={styles.qrCodeImage} />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )}
        </Row>
      </Container>
    </section>
  );
};

export default PaymentPixForm;
