import React from 'react';
import { Container, Row, Col, Button, Card, Image, Carousel, CarouselItem } from 'react-bootstrap';
import styles from "./style.module.scss"
import { Helmet, HelmetProvider } from 'react-helmet-async';
import SectionSearchHome from '../../sections/sectionSearchHome';
import Banner1 from "../../assets/banner1.png";
import Banner2 from "../../assets/banner2.png";
import Card1 from "../../assets/cardsHome/card1.png";
import Card2 from "../../assets/cardsHome/card2.png";
import Card3 from "../../assets/cardsHome/card3.png";
import Card4 from "../../assets/cardsHome/card4.png";
import Card5 from "../../assets/cardsHome/card5.png";
import Card6 from "../../assets/cardsHome/card6.png";
import Card7 from "../../assets/cardsHome/card7.png";
import Card8 from "../../assets/cardsHome/card8.png";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { useNavigate } from 'react-router-dom';

export default function HomePage() {
  const navigate = useNavigate();

  return (
    <HelmetProvider>
      <section id={styles.HomePage}>
        <Helmet>
          <title>Home - GoinGuru</title>
          <meta name="description" content="GoinGuru" />
        </Helmet>
        <SectionSearchHome />
        <Container className={styles.container}>
          <Row className={styles.carousel}>
            <Carousel>
              <CarouselItem>
                <Image className={styles.image} src={Banner1}/>
              </CarouselItem>
              <CarouselItem >
                <Image className={styles.image} src={Banner2}/>
              </CarouselItem>
            </Carousel>
          </Row>
        </Container>

        <div className={styles.rowCard}>
          <Container>
            <Row className={styles.title}>
              <p>Cadastre-se Gratuitamente</p>
              <p className={styles.subtitle}>Tenha o seu serviço divulgado em nosso site</p>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                <Card className={styles.card}>
                  <Row className={styles.rowImage}>
                    <Image className={styles.image} src={Card1} />
                  </Row>
                  <Row className={styles.cardBody}>
                    <p className={styles.text}>Versão gratuita para busca</p>
                  </Row>
                </Card>
              </Col>
              <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                  <Card className={styles.card}>
                    <Row className={styles.rowImage}>
                      <Image className={styles.image} src={Card2} />
                    </Row>
                    <Row className={styles.cardBody}>
                      <p className={styles.text}>Ganhe Visibilidade</p>
                    </Row>
                  </Card>
              </Col>
              <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                <Card className={styles.card}>
                  <Row className={styles.rowImage}>
                    <Image className={styles.image} src={Card3} />
                  </Row>
                  <Row className={styles.cardBody}>
                    <p className={styles.text}>Faça parte do Tops da Cidade</p>
                    <Button onClick={() => navigate('/topsDaCidade')}  className={styles.button} variant='primary'>Saiba Mais</Button>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
          <Row className={styles.rowPay}>
            <Card className={styles.cardPay}> 
              <Row className={styles.pay}>
                <Col xs={12} sm={12} md={12} lg={4} xl={4} className={styles.colImage}>
                  <Image className={styles.image} src={Card4}/>
                </Col>
                <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                  <p className={styles.title}>Faça transações de pagamento seguras</p>
                  <p className={styles.text}>Tenha segurança ao realizar pagamentos aos profissionais que está conhecendo pela primeira vez. A Goinguru te apoia com um sistema de reembolso em caso de golpes. Mas para isso, o seu pagamento precisa ser realizado através do nosso sistema de pagamento.</p>
                </Col>
              </Row>
            </Card>
          </Row>
        </Container>

        <div className={styles.rowCust}>
          <Container>
            <Row className={styles.title}>
                <p>Vantagens para o Cliente</p>
            </Row>
            <Row>
              <Col className={styles.colIcon}>
                <IoMdCheckmarkCircleOutline className={styles.icon}/>
              </Col>
              <Col className={styles.colIcon}>
                <IoMdCheckmarkCircleOutline className={styles.icon}/>
              </Col>
              <Col className={styles.colIcon}>
                <IoMdCheckmarkCircleOutline className={styles.icon}/>
              </Col>
              <Col className={styles.colIcon}>
                <IoMdCheckmarkCircleOutline className={styles.icon}/>
              </Col>
            </Row>
            <div className={styles.dashedLine}>
              <div className={styles.specialDot}></div>
              <div className={styles.specialDot}></div>
              <div className={styles.specialDot}></div>
              <div className={styles.specialDot}></div>
            </div>
            <Row>
              <Col className={styles.cust} xs={12} sm={6} md={6} lg={3} xl={3}>
                <Row className={styles.cardBody}>
                  <div className={styles.divIcon}>
                    <IoMdCheckmarkCircleOutline className={styles.icon}/>
                  </div>
                  <p className={styles.cTitle}>Encontre o seu parceiro</p>
                  <p className={styles.subtitle}>Encontre uma ampla gama de serviços em um único lugar, facilitando a busca e contratação de profissionais qualificados.</p>
                </Row>
              </Col>
              <Col className={styles.cust} xs={12} sm={6} md={6} lg={3} xl={3}>
                <Row className={styles.cardBody}>
                  <div className={styles.divIcon}>
                    <IoMdCheckmarkCircleOutline className={styles.icon}/>
                  </div>
                  <p className={styles.cTitle}>Peça um Orçamento</p>
                  <p className={styles.subtitle}>Caso não encontre um profissional na busca, você tem a opção de preencher um formulário. Nós te ajudamos a encontrar o profissional</p>
                </Row>
              </Col>
              <Col className={styles.cust} xs={12} sm={6} md={6} lg={3} xl={3}>
                <Row className={styles.cardBody}>
                  <div className={styles.divIcon}>
                    <IoMdCheckmarkCircleOutline className={styles.icon}/>
                  </div>
                  <p className={styles.cTitle}>Pagamento Seguro</p>
                  <p className={styles.subtitle}>Todas as negociações são realizadas dentro da plataforma, garantindo segurança e tranquilidade para ambas as partes.</p>
                </Row>
              </Col>
              <Col className={styles.cust} xs={12} sm={6} md={6} lg={3} xl={3}>
                <Row className={styles.cardBody}>
                  <div className={styles.divIcon}>
                    <IoMdCheckmarkCircleOutline className={styles.icon}/>
                  </div>
                  <p className={styles.cTitle} >Traga o seu prestador para a Goinguru</p>
                  <p className={styles.subtitle2}>Você já tem um prestador mas gostaria de ter a segurança de pagamento e evitar golpes? Compartilhe a Goinguru com ele e peça que ele emita a ordem de serviço por aqui. Assim você também terá um pagamento seguro.</p>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>

        <div className={styles.rowVcard}>
          <Container>
            <Row>
              <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <Row className={styles.Vtitle}>
                  <p>Vantagens para o Profissional</p>
                </Row>
                <Row className={styles.Vsubtitle}>
                  <p>A Goinguru oferece uma plataforma onde prestadores de serviço podem exibir seus serviços para uma ampla audiência, aumentando significativamente sua visibilidade e alcance.</p>
                </Row>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <Row>
                  <Col className={styles.Vcard} xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Row>
                      <div className={styles.colIcon}>
                        <Image src={Card5} className={styles.icon}/>
                      </div>
                      <p className={styles.NVtitle}>Segurança nas Transações</p>
                      <p className={styles.NVsubtitle}>Reduza o risco de inadimplência e tenha mais tranquilidade financeira.</p>
                    </Row>
                  </Col>
                  <Col className={styles.Vcard} xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Row>
                    <div className={styles.colIcon}>
                        <Image src={Card6} className={styles.icon}/>
                      </div>
                      <p className={styles.NVtitle}>Maior Visibilidade e Alcance</p>
                      <p className={styles.NVsubtitle}>Atraia mais clientes e expanda sua base de clientes potenciais.</p>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col className={styles.Vcard} xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Row>
                      <div className={styles.colIcon}>
                        <Image src={Card7} className={styles.icon}/>
                      </div>
                      <p className={styles.NVtitle}>Sistema de Agendamento</p>
                      <p className={styles.NVsubtitle}>Sistema de agendamento com pagamento integrado para profissionais que trabalham com agendas e horário marcado para a realização dos seus serviços.</p>
                    </Row>
                  </Col>
                  <Col className={styles.Vcard} xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Row>
                      <div className={styles.colIcon}>
                        <Image src={Card8} className={styles.icon}/>
                      </div>
                      <p className={styles.NVtitle} >Exposição para o Brasil</p>
                      <p className={styles.NVsubtitle}>Você faz atendimento online? O Brasil inteiro irá ver o seu serviço!</p>
                      </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className={styles.rowButton}>
              <Button href="https://parceiro.goinguru.com/signup" target="_blank" rel="noopener noreferrer" className={styles.Vbutton}>Quero fazer parte!</Button>
            </Row>
          </Container>
        </div>   
      </section>
    </HelmetProvider>
  );
}
