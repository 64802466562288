import React from 'react';
import { Nav, Navbar, Container, Image, NavDropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import logoMenu from "../../assets/logo.png";
import styles from "./style.module.scss";
import { useAuthContext } from '../../contexts/authContext';
import { AiOutlineUser } from 'react-icons/ai';

export default function Menu() {
  const navigate = useNavigate();
  const { logged, user, setToken} = useAuthContext();

  const handleLogout = async () => {
    localStorage.removeItem('token');
    await setToken(null);
    navigate('/');
  };

  const handleHome = () => {
    navigate('/');
  };

  return (
    <section id={styles.Menu}>
      <Navbar className={styles.navBar} expand="lg">
        <Container>
          <Navbar.Brand>
            <Image src={logoMenu} className={styles.logo} alt="Logo" onClick={handleHome} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className={styles.buttonNav}/>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className={styles.menuItens}>
              <Nav.Link as={Link} to="/sobre" className={styles.menuText}>SOBRE NÓS</Nav.Link>
              <a href="https://parceiro.goinguru.com" target="_blank" rel="noopener noreferrer" className={styles.menuText}>PARCEIRO</a>
              <Nav.Link as={Link} to="/topsDaCidade" className={styles.menuText}>TOPS DA CIDADE</Nav.Link>
              <Nav.Link as={Link} to="/blog" className={styles.menuText}>BLOG</Nav.Link>
              {!logged && (
                <NavDropdown title={<><AiOutlineUser className={styles.icon} /> Entrar</>} className={styles.menuDropdownText}>
                  <NavDropdown.Item as={Link} to="/login" className={styles.subText}>Login</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/signup" className={styles.subText}>Cadastrar-se</NavDropdown.Item>
                </NavDropdown>
              )}
              {logged && (
                <NavDropdown title={<><AiOutlineUser className={styles.icon} /> Olá {user.name.split(' ')[0]}</>} className={styles.menuDropdownText}>
                  <NavDropdown.Item as={Link} to="/meuPerfil" className={styles.subText}>Meu Perfil</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/meusPedidos" className={styles.subText}>Meus Pedidos</NavDropdown.Item>
                  <NavDropdown.Item className={styles.subText} onClick={handleLogout}>Sair</NavDropdown.Item>
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </section>
  );
}
