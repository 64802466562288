import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Card, Button, Spinner } from 'react-bootstrap';
import styles from "./style.module.scss";
import { useAuthContext } from '../../contexts/authContext';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from "../../api/default";

const PaymentCardForm = ( {ClientRequestData} ) => {
  const { token: jwtUser, user } = useAuthContext();
  const navigate = useNavigate();
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const formatCPF = (cpf) => cpf.replace(/\D/g, '');

  const [formData, setFormData] = useState({
    email: '',
    identificationNumber: '',
    identificationType: 'CPF',
  });

  useEffect(() => {
    if (user) {
      setFormData({
        email: user.email || '', 
        identificationNumber: formatCPF(user.cpf) || '',
        identificationType: 'CPF',
      });
    }
  }, [user]);

  useEffect(() => {

    if (!window.MercadoPago) {
      const script = document.createElement('script');
      script.src = "https://sdk.mercadopago.com/js/v2";
      script.onload = () => initializeCardForm();
      document.body.appendChild(script);
    } else {
      initializeCardForm();
    }
    
    function initializeCardForm() {
      const amountValue = ClientRequestData?.price ? ClientRequestData?.price.toString() : '0.00';

      const mp = new window.MercadoPago('APP_USR-4cd783d8-2604-4501-a894-4d54750d24b6', {
        locale: 'pt-BR'
      });

      const cardForm = mp.cardForm({
        amount: amountValue,
        iframe: true,
        form: {
          id: "form-checkout",
          cardNumber: {
            id: "form-checkout__cardNumber",
            placeholder: "Número do cartão",
          },
          expirationDate: {
            id: "form-checkout__expirationDate",
            placeholder: "MM/YY",
          },
          securityCode: {
            id: "form-checkout__securityCode",
            placeholder: "Código de segurança",
          },
          cardholderName: {
            id: "form-checkout__cardholderName",
            placeholder: "Titular do cartão",
          },
          issuer: {
            id: "form-checkout__issuer",
            placeholder: "Banco emissor",
          },
          installments: {
            id: "form-checkout__installments",
            placeholder: "Parcelas",
          },
          identificationType: {
            id: "form-checkout__identificationType",
            placeholder: "Tipo de documento",
          },
          identificationNumber: {
            id: "form-checkout__identificationNumber",
            placeholder: "Número do documento",
          },
          cardholderEmail: {
            id: "form-checkout__cardholderEmail",
            placeholder: "E-mail",
          },
        },
        callbacks: {
          onFormMounted: error => {
            if (error) return console.warn("Form Mounted handling error: ", error);
            console.log("Form mounted");
          },
          onSubmit: async event => {
            event.preventDefault();
            setIsLoadingButton(true);

            try {
              const {
                paymentMethodId: payment_method_id,
                issuerId: issuer_id,
                cardholderEmail: email,
                amount,
                token,
                installments,
                identificationNumber,
                identificationType,
              } = await cardForm.getCardFormData();

              const response = await fetch(`${BASE_URL}/payment/process-payment/`, {
                method: 'POST',
                headers: {
                  jwt: jwtUser,
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  client_order_id: ClientRequestData.id,
                  token,
                  issuer_id,
                  payment_method_id,
                  transaction_amount: Number(amount),
                  installments: Number(installments),
                  description: ClientRequestData.description,
                  payer: {
                    email,
                    identification: {
                      type: identificationType,
                      number: identificationNumber,
                    },
                  },
                }),
              });

              if (response.ok) {
                toast.success('Pagamento registrado com sucesso, aguarde a confirmação!.');
                navigate(`/meusPedidos/`);
              } else {
                toast.error('Erro ao registrar pagamento, tente novamente!');
                navigate(`/meusPedidos/`);
              }

            } catch (error) {
              toast.error('Erro ao registrar pagamento, tente novamente!');
              navigate(`/meusPedidos/`);
            }
          },
          onFetching: resource => {
            console.log("Fetching resource: ", resource);
          }
        },
      });
    }
  }, [jwtUser, ClientRequestData, navigate]);

  const handleInputChange = (event) => {
    const { id, value } = event.target;
  
    if (id === 'form-checkout__identificationNumber') {
      setFormData(prevState => ({
        ...prevState,
        identificationNumber: value,
      }));
    } else if(id === 'form-checkout__cardholderEmail'){
      setFormData(prevState => ({
        ...prevState,
        email: value,
      }));
    }
  };  

  return (
    <section id={styles.CheckoutPage}>
      <Container className={styles.checkoutContainer}>
        <Row>
          <Form id="form-checkout">
            <Card className={styles.card}>
              <Card.Title className={styles.cardTitle}>Informações do Cliente</Card.Title>
                <Row>
                  <Col xs={12} sm={6} md={6} lg={5} xl={5}>
                    <Form.Group controlId="form-checkout__identificationType">
                      <Form.Label className={styles.formLabel}>Tipo de Documento</Form.Label>
                      <select id="form-checkout__identificationType" className={`form-control ${styles.formControl}`}></select>
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={7} xl={7}>
                    <Form.Group controlId="form-checkout__identificationNumber">
                      <Form.Label className={styles.formLabel}>Número do Documento</Form.Label>
                      <input 
                        type="text" 
                        id="form-checkout__identificationNumber" 
                        className={`form-control ${styles.formControl}`} 
                        value={formData.identificationNumber}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group controlId="form-checkout__cardholderEmail">
                  <Form.Label className={styles.formLabel}>Email</Form.Label>
                  <input 
                    type="email" 
                    id="form-checkout__cardholderEmail" 
                    className={`form-control ${styles.formControl}`}
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </Form.Group>
            </Card>
            <Card className={styles.card}>
              <Row>
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Form.Group controlId="form-checkout__cardNumber">
                    <Form.Label className={styles.formLabel}>Número do cartão</Form.Label>
                    <div type="text" id="form-checkout__cardNumber" className={`form-control ${styles.divFormControl}`}/>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Form.Group controlId="form-checkout__cardholderName">
                    <Form.Label className={styles.formLabel}>Titular do Cartão</Form.Label>
                    <input type="text" id="form-checkout__cardholderName"  className={`form-control ${styles.formControl}`}/>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6} md={7} lg={6} xl={6}>
                  <Form.Group controlId="form-checkout__expirationDate">
                    <Form.Label className={styles.formLabel}>Data de Expiração (MM/YY)</Form.Label>
                    <div type="text" id="form-checkout__expirationDate" className={`form-control ${styles.divFormControl}`}/>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6} md={5} lg={6} xl={6}>
                  <Form.Group controlId="form-checkout__securityCode">
                    <Form.Label className={styles.formLabel}>CVV</Form.Label>
                    <div type="text" id="form-checkout__securityCode" className={`form-control ${styles.divFormControl}`}/>
                  </Form.Group>
                </Col>
                <Col hidden>
                  <Form.Group controlId="form-checkout__issuer">
                    <Form.Label className={styles.formLabel}>Banco Emissor</Form.Label>
                    <select id="form-checkout__issuer" className={`form-control ${styles.formControl}`}></select>
                  </Form.Group>
                </Col>
                <Form.Group controlId="form-checkout__installments">
                  <Form.Label className={styles.formLabel}>Parcelas</Form.Label>
                  <select id="form-checkout__installments" className={`form-control ${styles.formControl}`}></select>
                </Form.Group>
              </Row>

              <Row className={styles.rowButton}>
                <Button className={styles.button} type="submit" disabled={isLoadingButton}>
                    {isLoadingButton ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Pagar"}
                </Button>
              </Row>
            </Card>
          </Form>
        </Row>
      </Container>
    </section>
  );
};

export default PaymentCardForm;
